import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lazyload from 'react-lazyload';
import classNames from 'classnames';
// *************** Ref by https://github.com/facebook/react/issues/15446 ****************

function ImageSSR({ src, fallbackSrc, className, alt, customStyle, imgTitle }) {
  return (
    <span
    //   className="image"
      dangerouslySetInnerHTML={{
        __html: `
        <img 
          src="${src}" 
          class="${className}"
          data-fallback=${fallbackSrc} 
          onerror="this.onerror=null;this.src=this.dataset.fallback;" 
          style="${customStyle}"
          title="${imgTitle}"
          alt="${alt}" 
        />
          `
      }}
    />
  );
}

function CustomImage(props) {
  const { imgSrc, imgClass, className, customStyle, imgAlt, imgTitle } = props;
  const safeImgAlt = imgAlt ? imgAlt: "image";
  const saftClassName = className ? className: "";
  const safeImgTitle = imgTitle ? imgTitle: "";
  const imgSSR =  (<ImageSSR
    className={`${imgClass} ${saftClassName}`}
    src={imgSrc}
    fallbackSrc="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/broken-image-720x360.png"
    alt={safeImgAlt}
    customStyle={customStyle ? customStyle: ""}
    imgTitle={safeImgTitle}
  />)  
  return (
    <>
     {imgSSR}   
      {/* <Lazyload >
        {imgSSR}  
      </Lazyload>
      <noscript>
        <style>{`.lazyload-placeholder { display: none; }`}</style>
          
      </noscript> */}
    </>
  );
}

export default CustomImage;

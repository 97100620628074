import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import NewsItemParts from '/components/newsItemParts';
import NewsItemImgOrPoster from '/components/newsItemImgOrPoster';
import Lazyload from 'react-lazyload';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '0px 0px  18px',
  },
  textContainer: {
    padding: '0px 0px 0px 11px'
  },
  img: {
    background: 'rgba(128, 128, 128, 0.11)',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: 100,
    objectFit: 'cover'
  },
  customDivider: {
    margin: '18px 0px 0px'
  },
  customGraphicDivider: {
    margin: '6px 0px 14px'
  },
  playerIconStyle: {
    color: '#F9461E',
    position: 'absolute',
    bottom: 2,
    left: 2,
    fontSize: 25
  },
  playerIconWrapperStyle: {
    width: 32,
    height: 32,
    background: '#fff',
    position: 'absolute',
    borderRadius: '50%',
    bottom: 5,
    left: 4,
    border: '2px solid #F9461E'
  },

});

class MobileNewsItem extends React.Component {
  render(){
  const { item, classes, className, isInlineTeaser, removeDivider } = this.props;
  return (
    <>
    {
      item.type !== 'GRAPHIC' || isInlineTeaser ?
      <div className={classes.root}>
        <Lazyload
          throttle={100}
          height={100}
        >
          <Grid
            container
            direction="row"
            className={ isInlineTeaser ? "inline-teaser-gtm-track" : "suggested-article-gtm-track"}
          >
            <Grid xs={4} item>
              <NewsItemImgOrPoster item={item} className={className} imgHeight='100px' />
            </Grid>
            <Grid item xs={8} className={classes.textContainer}>
              <NewsItemParts item={item} className={className} />
            </Grid>
          </Grid>
        </Lazyload>
      {!removeDivider && <Divider className={classes.customDivider} />}
    </div>
    :
    <div style={{ marginBottom: 19 }}>
      <NewsItemImgOrPoster item={item} className={className} imgHeight='250px' />
      <div style={{ margin: '10px 0px 14px '}}>
        <NewsItemParts item={item} className={className} />
      </div>
      {!removeDivider && <Divider className={classes.customGraphicDivider} />}
    </div>
    }
    {/* {
    item.type === 'GRAPHIC' ?
    isInlineTeaser ?
    <div className={classes.root}>
      <Grid
        container
        direction="row"
      >
        <Grid xs={4} item>
          <NewsItemImgOrPoster item={item} className={className} imgHeight='100px' />
        </Grid>
        <Grid item xs={8} className={classes.textContainer}>
          <NewsItemParts item={item} className={className} />
        </Grid>
      </Grid>
      <Divider className={classes.customDivider} />
    </div>
    :
    <div>
      <NewsItemImgOrPoster item={item} className={className} imgHeight='250px' />
      <div style={{ marginTop: 10 }}>
        <NewsItemParts item={item} className={className} />
      </div>
      <Divider className={classes.customGraphicDivider} />
    </div>
    :
    <div className={classes.root}>
    <Grid
      container
      direction="row"
    >
      <Grid xs={4} item>
        <NewsItemImgOrPoster item={item} className={className} imgHeight='100px' />
      </Grid>
      <Grid item xs={8} className={classes.textContainer}>
        <NewsItemParts item={item} className={className} />
      </Grid>
    </Grid>
    <Divider className={classes.customDivider} />
  </div>
    } */}

    </>
  );
}
}

MobileNewsItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MobileNewsItem);

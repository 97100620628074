import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import Icon from '@material-ui/core/Icon';
import { Link } from "routes";
// import styled from 'styled-components';
import {getMobileNewsItemVideoThumbnail, getDesktopNewsItemVideoThumbnail, extractYoutubeVideoID} from '/utils/common'
// import SponsorChip from '/components/sponsorChip';
import CustomImage from '/components/customImage';
import classNames from 'classnames';
// import fontUniOrConvertZawgyi from '/utils/fontUniOrConvertZawgyi';
// import BrandTeaserLogo from '/components/brandTeaserLogo';
// import NewsItemParts from '/components/newsItemParts';
import { compose } from 'recompose';
import PlayIconButton from '/components/playIconButton';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '0px 0px  14px',
  },
  textContainer: {
    padding: '0px 0px 0px 14px'
  },
  img: {
    background: 'rgba(128, 128, 128, 0.11)',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    objectFit: 'cover',
   
  },
  mobileImg:{
    height: 100
  },
  customDivider: {
    margin: '14px 0px 0px'
  },
  playerIconStyle: {
    color: '#F9461E',
    position: 'absolute',
    bottom: 2,
    left: 2,
    fontSize: 25
  },
  playerIconWrapperStyle: {
    width: 32,
    height: 32, 
    background: '#fff',
    position: 'absolute',
    borderRadius: '50%',
    bottom: 5,
    left: 4,
    border: '2px solid #F9461E'
  },
  shadow: {   
    content: '""',
    width: '100%',
    position: 'absolute',
    // bottom: 4,
    top: 0,
    left: 0,
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',    
    zIndex: 1,
    opacity: 0,
    // opacity: 0.33,
    transition: 'opacity 0.5s ease-out',   
    // background: 'linear-gradient(0deg,rgba(0, 0, 0, 0.63) 20%,rgba(0, 0, 0, 0.51) 40%,rgba(0, 0, 0, 0.44) 49%,transparent)'
    '&:hover': {
      opacity: 0,
    }
  },     

});

class NewsItemImgOrPoster extends React.Component {
  constructor(props){
    super(props);
    this.articleVideoAndVideoItem = this.articleVideoAndVideoItem.bind(this);
  }
  articleVideoAndVideoItem = (props, suggestedPostClass, url) => {
    const { item, classes, className, imgHeight } = props;
    const isHostedVideoItem = item.media_id && item.posters;
    const videoUrlThumbnail = isHostedVideoItem ? item.posters.video_section_teaser : 
    (item.video_url ?  (this.props.width === 'xs' ? 
    getMobileNewsItemVideoThumbnail(extractYoutubeVideoID(item.video_url)) : 
    getDesktopNewsItemVideoThumbnail(extractYoutubeVideoID(item.video_url))) : '');
    const videoThumbnail = item.video ? item.video.posters.thumb : '' 
    return(
      <div style={{position: 'relative'}}>
        <Link route={url}> 
            <a className="anchor-link">
                <CustomImage // for video article
                  imgSrc={item.type === 'VIDEO' ? videoThumbnail : videoUrlThumbnail}
                  imgClass={classNames(classes.img, suggestedPostClass)} 
                  customStyle={`cursor: pointer; height: ${imgHeight};`} 
                />  
            </a>
        </Link>
        <Link route={url}>
            <a className="anchor-link">
                <PlayIconButton />
            </a>
        </Link>
      </div> 
    )

  }
  render(){
  const { item, classes, className, imgHeight } = this.props;
  const suggestedPostClass = this.props.className ? this.props.className : 'article';
  const color = item.category.color ? item.category.color : '#F9461E' ;
  
  return (
    !item.type ?
    this.articleVideoAndVideoItem(this.props, suggestedPostClass, `/article/${item.slug}`)
    :
    item.type === 'VIDEO' || item.type === 'VIDEO_URL' || item.video || item.video_url ?
      this.articleVideoAndVideoItem(this.props, suggestedPostClass, `/article/${item.slug}`)
      
        :
        item.type === 'GRAPHIC' || item.graphic && item.graphic.thumb ?
          <Link route={`/article/${item.slug}`}>
            <a className="anchor-link">
            <picture>
              {/* {
              item.graphic.thumb_webp &&
              <source srcSet={item.graphic.thumb_webp} type="image/webp" />
              } */}
                <CustomImage // for graphic article
                  imgSrc={item.graphic.thumb}
                  imgClass={classNames(classes.img, className)} 
                  customStyle={`cursor: pointer; height: ${imgHeight}; objectFit: contain`} 
                />
            </picture>
            </a>
          </Link>
        :
        <Link route={`/article/${item.slug}`}>
          <a className="anchor-link">
              {this.props.width === 'xs' ?
                  <span style={{ overflowY: 'auto', height: 100, position: 'relative', display: 'block' }}>
            <span className={classes.shadow} style={{backgroundColor: color, height: 100}}></span>

                      <CustomImage // for article (mobile view)
                        imgSrc={item.cover.thumb_mobile} 
                        imgClass={classes.img} 
                        className={classNames(classes.mobileImg, suggestedPostClass)} 
                      />
                  </span>
                  :
                  <picture style={{position: 'relative', display: 'block'}}>
            <span className={classes.shadow} style={{backgroundColor: color, height: imgHeight}}></span>

                  {/* {
                  item.cover.thumb_webp &&
                  <source srcSet={item.cover.thumb_webp} type="image/webp" />
                  } */}
                  <CustomImage 
                    imgSrc={item.cover.thumb}  // for article (desktop view)
                    imgClass={classNames(classes.img, className)} 
                    customStyle={`cursor: pointer; height: ${imgHeight}`} 
                  />
                  </picture>                                    
              }
              </a>
          </Link>
        );
    }
}

NewsItemImgOrPoster.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
    withWidth(),
    withStyles(styles))(NewsItemImgOrPoster);

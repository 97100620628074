import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TimeAgo from "react-timeago";
import { Link } from "routes";
import styled from 'styled-components';
import SponsorChip from '/components/sponsorChip';
import fontUniOrConvertZawgyi from '/utils/fontUniOrConvertZawgyi';
import BrandTeaserLogo from '/components/brandTeaserLogo';
import AuthorAndTime from '/components/authorAndTime';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '0px 0px  14px',
  },
  time: {
    cursor: 'pointer',
    fontSize: 12,
    display: 'inline',
  },
  categoryName: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      fontSize: 11,
      paddingLeft: 10,
      fontWeight: 'bold',
      lineHeight: '14px'
  },
  title: {
    cursor: 'pointer',
    lineHeight: '25px',
    fontWeight: 'bold',
    fontSize: 16,
    overflowWrap: 'break-word'
  },
});

class NewsItemParts extends React.Component {
  render(){
  const { item, classes, className, isGraphicAndCover, customStyle, color, isCover, isFeatureCover, customTitleStyle, typoComponent } = this.props;
  const suggestedPostClass = this.props.className ? this.props.className : 'article';
  const isHostedVideoItem = item.media_id && item.posters;
  if(isHostedVideoItem) {
    // let videoCategories = item.categories && item.categories.length ? item.categories.filter(child => child.type == 'TV' || child.type == 'VIDEO') : null;
    // item.category = item.categories && item.categories.length ? (videoCategories && videoCategories[0]) || item.categories[0] : null ;
    // const videoItemCategory = item.categories.find((item) => item.type == 'VIDEO' || item.type == 'TV');
    item.category = item.categories && item.categories.length ? item.categories[0] : null;
    item.path = item.category ? '/' + (item.category.type == 'TV' ? 'tv' : 'videos') + '/' + item.slug : '#';
  }

  const CategoryTitleStyle = styled.span`
      position: relative;
      &::before {
        content: "";
        background: ${item.category.color};
        height: 9px;
        position: absolute;
        width: 3px;
        top: 3px;
      },
  `;
  return (
    <div>
        <Grid container justify="space-between">
            <Grid item className="category-tag-gtm">
                <CategoryTitleStyle />
                <Link route={`${item.category.path}`}>
                    <a className="anchor-link">
                        <Typography
                        gutterBottom
                        style={{
                                color: isCover ? color : item.category.color
                            }}
                        className={classes.categoryName}
                        >
                        <span className="sub_categories">
                            {item.category.name}
                        </span>
                        </Typography>
                    </a>
                </Link>
            </Grid>
            {
              isGraphicAndCover === undefined &&
              <Grid item style={{ display: 'flex' }}>
              {item.sponsor != null ? <BrandTeaserLogo brandTeaserLogo={item.sponsor.logo} brandSlug={item.sponsor.slug} />: null} 
              {item.is_sponsor ? <SponsorChip /> : null} 
          </Grid>
            } 
           
        </Grid>
        {
          isGraphicAndCover === undefined || isFeatureCover ? <Link route={!item.type ? item.path : `/article/${item.slug}`}>
            <a className="anchor-link">
                <Typography component={typoComponent ? typoComponent : "div"} gutterBottom className={classes.title} style={{...customStyle}}>
                    <div className={suggestedPostClass} style={{...customTitleStyle}}>
                        {fontUniOrConvertZawgyi(item.title)}
                    </div>
                </Typography>
            </a>
        </Link>
        :
        <Typography component={typoComponent ? typoComponent : "div"} gutterBottom className={classes.title} style={{ cursor: 'initial', ...customStyle}}>
          <div className={suggestedPostClass} style={{...customTitleStyle}}>
              {fontUniOrConvertZawgyi(item.title)}
          </div>
        </Typography>
        }
        {
          isGraphicAndCover === undefined &&
          <AuthorAndTime item={item} linkColor="rgba(0, 0, 0, 0.8705882352941177)" />
        }
    </div>
  );
}
}

NewsItemParts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewsItemParts);

import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({ 
  sponsorStyle: {
    padding: '1px 5px',
    borderRadius: 3,
    textTransform: 'uppercase',
    fontSize: 11,
    opacity: 0.8
  }
})

class SponsorChip extends Component {
  render() {
    const { classes } = this.props;
    return (
         <Grid item>
            <Typography
              gutterBottom
              className={classes.sponsorStyle}
            >
             Sponsored
            </Typography>
          </Grid>
    );
  }
}

export default withStyles(styles)(SponsorChip);

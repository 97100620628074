import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lazyload from 'react-lazyload';
import NewsItemParts from '/components/newsItemParts';
import NewsItemImgOrPoster from '/components/newsItemImgOrPoster';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 10
  },
  imageContainer: {
    width: '100%',
    height: 145,
  },

});

function DesktopNewsItem(props) {
  const { item, classes,className } = props;
  // const color = item.category.color;

  return (
    <div className={classNames(classes.root, 'suggested-article-gtm-track')}>
      <Lazyload
        throttle={100}
        height={100}
      >
        <div className={classes.imageContainer}>
         <NewsItemImgOrPoster item={item} className={className} imgHeight='145px' />
        </div>
        <div style={{marginTop: 10}}>
          <NewsItemParts item={item} className={className} />
        </div>
      </Lazyload>
    </div>
  );
}

DesktopNewsItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DesktopNewsItem);
